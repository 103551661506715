export const CustomerType = {
  SUSPECT: 'Suspect',
  PROSPECT: 'Prospect',
  CLIENT: 'Client'
};

export const CustomerTypeMap = {
  [CustomerType.SUSPECT]: 1,
  [CustomerType.PROSPECT]: 2,
  [CustomerType.CLIENT]: 3
};

export const PROFESSION = [
  { title: 'Framework', value: 1 },
  { title: 'Official', value: 2 },
  { title: 'Farmer', value: 3 }
];

export const MARITAL = [
  { title: 'Single', value: 1 },
  { title: 'Widower', value: 2 },
  { title: 'Married', value: 3 }
];

export function getCustomerType(type) {
  return CustomerTypeMap[type];
}

export const LAST_STEP_OPTIONS = [
  {title: '1. CREATION SALE IN PROGRESS', value: 0, disabled: true},
  {title: 'SIGNED AND ACCEPTED PURCHASE OFFER', value: 1},
];

export const CLIENT_LAST_STEP = LAST_STEP_OPTIONS.reduce((prev, curr) => ({
  ...prev,
  [curr.value]: curr.title,
}), {});
