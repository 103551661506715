import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Grid } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { CustomerType } from './CustomerUtils';
import CustomerCommonFormFields from './create_customer/CustomerCommonFormFields';
import CustomerMaritalFormFields from './create_customer/CustomerMaritalFormFields';
import CustomerVendorInfoFields from './create_customer/CustomerVendorInfoFields';
import CustomerNotaryInfoFields from './create_customer/CustomerNotaryInfoFields';
import CustomerFinancialInfoFields from './create_customer/CustomerFinancialInfoFields';
import CustomerCoOwnershipFields from './create_customer/CustomerCoOwnershipFields';
import CustomerProjectFundingFormFields from './create_customer/CustomerProjectFundingFormFields';
import CustomerFeesFields from './create_customer/CustomerFeesFields';
import CustomerFileManagementFields from './create_customer/CustomerFileManagementFields';

// ----------------------------------------------------------------------
function getDate(rawDate) {
  const d = new Date(rawDate);
  let month = d.getMonth() + 1;
  if (month < 9) {
    month = `0${month}`;
  }
  let date = d.getDate();
  if (date < 9) {
    date = `0${date}`;
  }
  return `${d.getFullYear()}-${month}-${date}`;
}

export default function CreateCustomerForm({
  onFormSubmit,
  type,
  mode,
  customer,
  customerSourceList,
  projects
}) {
  let SchemaInfo = {
    name: Yup.string().required('Name is required'),
    email: Yup.string().email().required('Email is required'),
    profession: Yup.number(),
    phone: Yup.string().required('Phone is required'),
    date_of_birth: Yup.date(),
    place_of_birth: Yup.string(),
    nationality: Yup.string(),
    postal_code: Yup.string(),
    name_additional: Yup.string(),
    email_additional: Yup.string().email(),
    profession_additional: Yup.number(),
    phone_additional: Yup.string(),
    date_of_birth_additional: Yup.date(),
    place_of_birth_additional: Yup.string(),
    nationality_additional: Yup.string(),
    type: Yup.number().required('Type is required'),
    customer_source: Yup.number(),
    projects: Yup.array()
  };

  if (type === CustomerType.CLIENT) {
    SchemaInfo = {
      ...SchemaInfo,
      marital_status: Yup.number(),
      marital_status_additional: Yup.number(),
      vendor_name: Yup.string().required('Name is required'),
      vendor_email: Yup.string().email().required('Email is required'),
      vendor_profession: Yup.number(),
      vendor_phone: Yup.string().required('Phone is required'),
      vendor_date_of_birth: Yup.date(),
      vendor_place_of_birth: Yup.string(),
      vendor_nationality: Yup.string(),
      vendor_name_additional: Yup.string(),
      vendor_email_additional: Yup.string().email(),
      vendor_profession_additional: Yup.number(),
      vendor_phone_additional: Yup.string(),
      vendor_date_of_birth_additional: Yup.date(),
      vendor_place_of_birth_additional: Yup.string(),
      vendor_nationality_additional: Yup.string(),
      vendor_address: Yup.string(),
      vendor_property_address: Yup.string(),
      // Notaries
      notary_seller_name: Yup.string(),
      notary_seller_email: Yup.string().email(),
      notary_seller_phone: Yup.string(),
      notary_buyer_name: Yup.string(),
      notary_buyer_email: Yup.string().email(),
      notary_buyer_phone: Yup.string(),
      date_of_compromise: Yup.date(),
      dia_end_date: Yup.date(),
      date_of_act: Yup.date(),
      // financial elements
      net_selling_price: Yup.number(),
      hai_sale_price: Yup.number(),
      sales_mandate_number: Yup.string(),
      security_deposit: Yup.number(),
      mandate: Yup.number(),
      agency_fees: Yup.number(),
      // Co-ownership
      joint_property: Yup.string(),
      trustee_contact: Yup.string(),
      lots_sold: Yup.number(),
      coownership_lots: Yup.number(),
      condominium_fees: Yup.string(),
      procedure_progress: Yup.number(),
      property_use: Yup.number(),
      // project funding
      purchase_type: Yup.number(),
      total_funding_amount: Yup.number(),
      funds_source: Yup.string(),
      suspensive_conditions: Yup.number(),
      project_contribution: Yup.number(),
      name_of_bank: Yup.string(),
      loan_rate: Yup.string(),
      bank_email: Yup.string().email(),
      bank_phone: Yup.string(),
      loan_term: Yup.number(),
      loan_amount: Yup.string(),
      bank_deposit_date: Yup.date(),
      loan_agreement_date: Yup.date(),
      loan_acceptance_date: Yup.date(),
      // Customer fees
      agency_ttc: Yup.number(),
      fees_vat: Yup.number(),
      fees_rate: Yup.number(),
      interagency: Yup.number(),
      interagency_name: Yup.string(),
      hono_interagency: Yup.number(),
      // File management
      missing_parts_file: Yup.string(),
      last_step: Yup.number(),
      observations: Yup.string(),
    };
  }

  const CustomerSchema = Yup.object().shape(SchemaInfo);

  let initialValues = {
    name: '',
    email: '',
    profession: '',
    phone: '',
    date_of_birth: '',
    place_of_birth: '',
    nationality: '',
    postal_code: '',
    name_additional: '',
    email_additional: '',
    profession_additional: '',
    phone_additional: '',
    date_of_birth_additional: '',
    place_of_birth_additional: '',
    nationality_additional: '',
    type,
    customer_source: '',
    address_of_buyers: '',
    projects: []
  };

  if (type === CustomerType.CLIENT) {
    initialValues = {
      ...initialValues,
      marital_status: 1,
      marital_status_additional: 1,
      vendor_name: '',
      vendor_email: '',
      vendor_profession: '',
      vendor_phone: '',
      vendor_date_of_birth: '',
      vendor_place_of_birth: '',
      vendor_nationality: '',
      vendor_name_additional: '',
      vendor_email_additional: '',
      vendor_profession_additional: '',
      vendor_phone_additional: '',
      vendor_date_of_birth_additional: '',
      vendor_place_of_birth_additional: '',
      vendor_nationality_additional: '',
      vendor_address: '',
      vendor_property_address: '',
      // Notaries
      notary_seller_name: '',
      notary_seller_email: '',
      notary_seller_phone: '',
      notary_buyer_name: '',
      notary_buyer_email: '',
      notary_buyer_phone: '',
      date_of_compromise: '',
      dia_end_date: '',
      date_of_act: '',
      // financial
      net_selling_price: '',
      hai_sale_price: '',
      sales_mandate_number: '',
      security_deposit: '',
      mandate: '',
      agency_fees: '',
      // co-ownership
      joint_property: '',
      trustee_contact: '',
      lots_sold: '',
      coownership_lots: '',
      condominium_fees: '',
      procedure_progress: '',
      property_use: '',
      // project funding
      purchase_type: '',
      total_funding_amount: '',
      funds_source: '',
      suspensive_conditions: '',
      project_contribution: '',
      name_of_bank: '',
      loan_rate: '',
      bank_email: '',
      bank_phone: '',
      loan_term: '',
      loan_amount: '',
      bank_deposit_date: '',
      loan_agreement_date: '',
      loan_acceptance_date: '',
      // Customer fees
      agency_ttc: '',
      fees_vat: '',
      fees_rate: '',
      interagency: '',
      interagency_name: '',
      hono_interagency: '',
      // File management
      missing_parts_file: '',
      last_step: '',
      observations: '',
    };
  }

  const dateFields = [
    'date_of_birth',
    'date_of_birth_additional',
    'vendor_date_of_birth',
    'vendor_date_of_birth_additional',
    'date_of_compromise',
    'dia_end_date',
    'date_of_act',
    'bank_deposit_date',
    'loan_agreement_date',
    'loan_acceptance_date'
  ];
  const multiField = ['projects'];

  if (mode === 3 && customer != null) {
    initialValues = Object.keys(initialValues).reduce(
      (prev, currKey) => ({
        ...prev,
        [currKey]: customer[currKey] ?? initialValues[currKey]
      }),
      {}
    );

    multiField.forEach((field) => {
      if (initialValues[field] && !Array.isArray(initialValues[field])) {
        initialValues[field] = initialValues[field].split(',');
      }
    });

    dateFields.forEach((d) => {
      if (initialValues[d]) {
        initialValues[d] = getDate(initialValues[d]);
      }
    });
  }
  const formik = useFormik({
    initialValues,
    validationSchema: CustomerSchema,
    onSubmit: (values) => {
      onFormSubmit(values, setSubmitting, customer ? customer.id : null);
    }
  });

  const { errors, touched, values, isSubmitting, setSubmitting, handleSubmit, getFieldProps } =
    formik;

  if (mode === 3 && customer === null) {
    return null;
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <CustomerCommonFormFields
            getFieldProps={getFieldProps}
            touched={touched}
            errors={errors}
            customerSourceList={customerSourceList}
            projects={projects}
          />
          {type === CustomerType.CLIENT && (
            <>
              <CustomerMaritalFormFields
                getFieldProps={getFieldProps}
                touched={touched}
                errors={errors}
              />
              <CustomerVendorInfoFields
                getFieldProps={getFieldProps}
                touched={touched}
                errors={errors}
              />
              <CustomerNotaryInfoFields
                getFieldProps={getFieldProps}
                touched={touched}
                errors={errors}
              />
              <CustomerFinancialInfoFields
                getFieldProps={getFieldProps}
                touched={touched}
                errors={errors}
              />
              <CustomerCoOwnershipFields
                getFieldProps={getFieldProps}
                touched={touched}
                errors={errors}
              />
              <CustomerProjectFundingFormFields
                getFieldProps={getFieldProps}
                touched={touched}
                errors={errors}
                values={values}
              />
              <CustomerFeesFields
                getFieldProps={getFieldProps}
                touched={touched}
                errors={errors}
                values={values}
              />
              <CustomerFileManagementFields
                getFieldProps={getFieldProps}
                touched={touched}
                errors={errors}
              />
            </>
          )}
          <Grid item xs={12} sm={12}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
