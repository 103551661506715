import FormBuilder, { FIELD_TYPE } from '../../../FormBuilder';

import { MARITAL } from '../CustomerUtils';

const FIELDS_CONFIG = [
  {
    type: FIELD_TYPE.SELECT,
    break: 6,
    label: 'Marital Status 1',
    key: 'marital_status',
    options: MARITAL
  },
  {
    type: FIELD_TYPE.SELECT,
    break: 6,
    label: 'Marital Status 2',
    key: 'marital_status_additional',
    options: MARITAL
  }
];
export default function CustomerMaritalFormFields({ getFieldProps, touched, errors }) {
  return (
    <FormBuilder
      fieldsConfig={FIELDS_CONFIG}
      getFieldProps={getFieldProps}
      touched={touched}
      errors={errors}
      title="Situation of the purchaser (s)"
    />
  );
}
