import {  useNavigate } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Card, Stack, Container, Typography } from '@material-ui/core';
// components
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {makeUnAuthenticatedCall} from '../api/api';
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
import { setUserToken } from '../utils/localStorage';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems:'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');

  const onLoginFormSubmit = useCallback(
    (values, setSubmitting) => {
      makeUnAuthenticatedCall('auth/login', {
        method: 'POST',
        body: JSON.stringify({
          email: values.email,
          password: values.password
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          setSubmitting(false);
          if (response.errors) {
            setErrorMessage(response.errors[0].message);
            return;
          }

          if (response.data && response.data.access_token) {
            setUserToken(response.data.access_token);
            navigate('/dashboard', { replace: true });
          }
        })
        .catch(() => {
          setErrorMessage('Unexpected error happened');
        });
    },
    [navigate]
  );

  return (
    <RootStyle title="Login | Storm">
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 0, mb: 5 }}>
            {t('login.welcome')}
          </Typography>
          <img style={{ width: '80%' }} src="/static/illustrations/storm-logo-02.svg" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
             {t('login.signin')}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>{t('login.enter_details')}</Typography>
            <Typography sx={{ color: 'text.secondary' }}>{errorMessage}</Typography>
          </Stack>
          <LoginForm onFormSubmit={onLoginFormSubmit} />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
