import { Grid, Typography, Stack, TextField } from '@material-ui/core';

export default function CustomerNotaryInfoFields({ getFieldProps, touched, errors }) {
  return (
    <>
      <Grid item xs={12}>
        <Stack direction="column" alignItems="center" spacing={2}>
          <Typography variant="body1">Notaries information</Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          autoComplete="name"
          label="Notary of Seller"
          size="small"
          {...getFieldProps('notary_seller_name')}
          error={Boolean(touched.notary_seller_name && errors.notary_seller_name)}
          helperText={touched.notary_seller_name && errors.notary_seller_name}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          autoComplete="name"
          label="Notary of purchaser"
          size="small"
          {...getFieldProps('notary_buyer_name')}
          error={Boolean(touched.notary_buyer_name && errors.notary_buyer_name)}
          helperText={touched.notary_buyer_name && errors.notary_buyer_name}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          autoComplete="phone"
          label="Phone 1"
          size="small"
          {...getFieldProps('notary_seller_phone')}
          error={Boolean(touched.notary_seller_phone && errors.notary_seller_phone)}
          helperText={touched.notary_seller_phone && errors.notary_seller_phone}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          autoComplete="phone"
          label="Phone 2"
          size="small"
          {...getFieldProps('notary_buyer_phone')}
          error={Boolean(touched.notary_buyer_phone && errors.notary_buyer_phone)}
          helperText={touched.notary_buyer_phone && errors.notary_buyer_phone}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          autoComplete="email"
          label="Email 1"
          size="small"
          {...getFieldProps('notary_seller_email')}
          error={Boolean(touched.notary_seller_email && errors.notary_seller_email)}
          helperText={touched.notary_seller_email && errors.notary_seller_email}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          autoComplete="email"
          label="Email 2"
          size="small"
          {...getFieldProps('notary_buyer_email')}
          error={Boolean(touched.notary_buyer_email && errors.notary_buyer_email)}
          helperText={touched.notary_buyer_email && errors.notary_buyer_email}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="Date of compromise"
          size="small"
          type="date"
          {...getFieldProps('date_of_compromise')}
          error={Boolean(touched.date_of_compromise && errors.date_of_compromise)}
          helperText={touched.date_of_compromise && errors.date_of_compromise}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="DIA end date"
          size="small"
          type="date"
          {...getFieldProps('dia_end_date')}
          error={Boolean(touched.dia_end_date && errors.dia_end_date)}
          helperText={touched.dia_end_date && errors.dia_end_date}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="Date of act"
          size="small"
          type="date"
          {...getFieldProps('date_of_act')}
          error={Boolean(touched.date_of_act && errors.date_of_act)}
          helperText={touched.date_of_act && errors.date_of_act}
        />
      </Grid>
    </>
  );
}
