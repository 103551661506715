import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Typography,
  Stack,
  TextField
} from '@material-ui/core';

import { PROFESSION } from '../CustomerUtils';

export default function CustomerVendorInfoFields({ getFieldProps, touched, errors }) {
  return (
    <>
      <Grid item xs={12}>
        <Stack direction="column" alignItems="center" spacing={2}>
          <Typography variant="body1">Vendor information</Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          autoComplete="name"
          label="Full Name 1"
          size="small"
          {...getFieldProps('vendor_name')}
          error={Boolean(touched.vendor_name && errors.vendor_name)}
          helperText={touched.vendor_name && errors.vendor_name}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          autoComplete="name"
          label="Full Name 2"
          size="small"
          {...getFieldProps('vendor_name_additional')}
          error={Boolean(touched.vendor_name_additional && errors.vendor_name_additional)}
          helperText={touched.vendor_name_additional && errors.vendor_name_additional}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          autoComplete="phone"
          label="Phone 1"
          size="small"
          {...getFieldProps('vendor_phone')}
          error={Boolean(touched.vendor_phone && errors.vendor_phone)}
          helperText={touched.vendor_phone && errors.vendor_phone}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          autoComplete="phone"
          label="Phone 2"
          size="small"
          {...getFieldProps('vendor_phone_additional')}
          error={Boolean(touched.vendor_phone_additional && errors.vendor_phone_additional)}
          helperText={touched.vendor_phone_additional && errors.vendor_phone_additional}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          autoComplete="email"
          label="Email 1"
          size="small"
          {...getFieldProps('vendor_email')}
          error={Boolean(touched.vendor_email && errors.vendor_email)}
          helperText={touched.vendor_email && errors.vendor_email}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          autoComplete="email"
          label="Email 2"
          size="small"
          {...getFieldProps('vendor_email_additional')}
          error={Boolean(touched.vendor_email_additional && errors.vendor_email_additional)}
          helperText={touched.vendor_email_additional && errors.vendor_email_additional}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel id="select-prof-label">Profession 1</InputLabel>
          <Select size="small" labelId="select-prof-label" {...getFieldProps('vendor_profession')}>
            {PROFESSION.map((prof) => (
              <MenuItem key={prof.value} value={prof.value}>
                {prof.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel id="select-profadd-label">Profession 2</InputLabel>
          <Select
            size="small"
            labelId="select-profadd-label"
            {...getFieldProps('vendor_profession_additional')}
          >
            {PROFESSION.map((prof) => (
              <MenuItem key={prof.value} value={prof.value}>
                {prof.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Date of Birth 1"
          size="small"
          type="date"
          {...getFieldProps('vendor_date_of_birth')}
          error={Boolean(touched.vendor_date_of_birth && errors.vendor_date_of_birth)}
          helperText={touched.vendor_date_of_birth && errors.vendor_date_of_birth}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          autoComplete="email"
          label="Date of Birth 2"
          size="small"
          type="date"
          {...getFieldProps('vendor_date_of_birth_additional')}
          error={Boolean(
            touched.vendor_date_of_birth_additional && errors.vendor_date_of_birth_additional
          )}
          helperText={
            touched.vendor_date_of_birth_additional && errors.vendor_date_of_birth_additional
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Place of Birth 1"
          size="small"
          {...getFieldProps('vendor_place_of_birth')}
          error={Boolean(touched.vendor_place_of_birth && errors.vendor_place_of_birth)}
          helperText={touched.vendor_place_of_birth && errors.vendor_place_of_birth}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Place of Birth 2"
          size="small"
          {...getFieldProps('vendor_place_of_birth_additional')}
          error={Boolean(
            touched.vendor_place_of_birth_additional && errors.vendor_place_of_birth_additional
          )}
          helperText={
            touched.vendor_place_of_birth_additional && errors.vendor_place_of_birth_additional
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Nationality"
          size="small"
          {...getFieldProps('vendor_nationality')}
          error={Boolean(touched.vendor_nationality && errors.vendor_nationality)}
          helperText={touched.vendor_nationality && errors.vendor_nationality}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          autoComplete="email"
          label="Nationality 2"
          size="small"
          {...getFieldProps('vendor_nationality_additional')}
          error={Boolean(
            touched.vendor_nationality_additional && errors.vendor_nationality_additional
          )}
          helperText={touched.vendor_nationality_additional && errors.vendor_nationality_additional}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          fullWidth
          multiline
          rows={3}
          label="Vendor address"
          size="small"
          {...getFieldProps('vendor_address')}
          error={Boolean(touched.vendor_address && errors.vendor_address)}
          helperText={touched.vendor_address && errors.vendor_address}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          fullWidth
          multiline
          rows={3}
          label="Address of property sold"
          size="small"
          {...getFieldProps('vendor_property_address')}
          error={Boolean(touched.vendor_property_address && errors.vendor_property_address)}
          helperText={touched.vendor_property_address && errors.vendor_property_address}
        />
      </Grid>
    </>
  );
}
