import { getUserToken } from '../utils/localStorage';

const BASE_URL = process.env.REACT_APP_API_URL;

export function makeUnAuthenticatedCall(uri, options = {}) {
  const { method, body } = options;
  return fetch(`${BASE_URL}${uri}`, {
    method: method || 'GET',
    body,
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

export function makeAuthenticatedCall(uri, options = {}) {
  const { method, body } = options;
  return fetch(`${BASE_URL}${uri}`, {
    method: method || 'GET',
    body,
    headers: {
      Authorization: `Bearer ${getUserToken()}`,
      'Content-Type': 'application/json'
    }
  });
}
