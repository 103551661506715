/* eslint-disable camelcase */
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useEffect, useState, useCallback, useContext } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TablePagination
} from '@material-ui/core';
import FormDialog from '../components/FormDialog';
import TypeContext from '../context/TypeContext';
import UserContext from '../context/UserContext';
import { setUserToken } from '../utils/localStorage';

// material
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import DataTable from '../components/_dashboard/DataTable';
import CreateProjectForm from '../components/_dashboard/projects/CreateProjectForm';
import {  UserListToolbar } from '../components/_dashboard/user';
import { makeAuthenticatedCall } from '../api/api';
import { NumberRenderer, StringRenderer } from '../components/_dashboard/renderers';
import ProjectMoreMenu from '../components/_dashboard/projects/ProjectMoreMenu';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', renderer: ({data}) => <StringRenderer value={data.name} /> },
  { id: 'min_budget', label: 'Min Budget', renderer: ({data}) => <NumberRenderer value={data.min_budget} /> },
  { id: 'max_budget', label: 'Max Budget', renderer: ({data}) => <NumberRenderer value={data.max_budget} /> },
  { id: 'min_area', label: 'Min Area  ', renderer: ({data}) => <NumberRenderer value={data.min_area} /> }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const ProjectDialogMode = {
  NEW: 1,
  VIEW: 2,
  EDIT: 3
};

export default function Projects() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [projects, setProjects] = useState([]);
  const [actionProjectID, setActionProjectID] = useState(-1);
  const [projectsByID, setProjectsByID] = useState({});
  const [mode, setMode] = useState(ProjectDialogMode.NEW);
  const [isCreateProjectOpen, setCreateProjectOpen] = useState(false);
  const types = useContext(TypeContext);
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const goalList = types.goal || [];
  const withinList = types.within || [];
  const kindList = types.kind || [];
  const typologyList = types.typology || [];

  useEffect(() => {
    makeAuthenticatedCall('storm/projects')
      .then((response) => {
        if (response.status === 401) {
          setUserToken('');
          navigate('/login', { replace: true });
        }
        return response.json();
      })
      .then((response) => {
        if (response.data) {
          setProjects(response.data);
        }
      });
  }, [navigate]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const filteredProjects = applySortFilter(projects, getComparator(order, orderBy), filterName);

  const onOrderChange = useCallback((isAsc, property) => {
    setOrder(isAsc ? 'desc': 'asc');
    setOrderBy(property);
  },[setOrder, setOrderBy]);


  const onCreateProject = useCallback(
    (values, setSubmitting) => {
      makeAuthenticatedCall('storm/projects', { method: 'POST', body: JSON.stringify(values) })
        .then((response) => {
          setSubmitting(false);
          setCreateProjectOpen(false);
          if (response.status === 401) {
            setUserToken('');
            navigate('/login', { replace: true });
          }
          return response.json();
        })
        .then((response) => {
          if (response.success) {
            setProjects([...projects, response.data]);
          }
        });
    },
    [projects, setProjects, navigate]
  );

  const fetchProjectByID = useCallback(
    (projectID) => {
      makeAuthenticatedCall(`storm/projects/${projectID}`)
        .then((response) => {
          if (response.status === 401) {
            setUserToken('');
            navigate('/login', { replace: true });
          }
          return response.json();
        })
        .then((response) => {
          if (response.success) {
            setProjectsByID({
              ...projectsByID,
              [projectID]: response.data
            });
          }
        });
  }, [navigate, projectsByID]);

  const onDeleteProject = useCallback((projectIdx) => {
    const idx = page * rowsPerPage + projectIdx;
    const projID = filteredProjects[idx].id;
    makeAuthenticatedCall(`storm/projects/${projID}`, {
      method: 'DELETE'
    })
      .then((response) => {
        if (response.status === 401) {
          setUserToken('');
          navigate('/login', { replace: true });
        }
        return response.json();
      })
      .then((response) => {
        if (response.success) {
          setProjects(projects.filter((p) => p.id !== projID));
        }
      });

  }, [projects, setProjects, page, rowsPerPage, navigate, filteredProjects]);

  const onEditClick = useCallback(
    (projectIndex) => {
      const idx = page * rowsPerPage + projectIndex;
      const projectID = filteredProjects[idx].id;
      fetchProjectByID(projectID);
      setActionProjectID(projectID);
      setMode(ProjectDialogMode.EDIT);
      setCreateProjectOpen(true);
    },
    [page, rowsPerPage, filteredProjects, fetchProjectByID]
  );

  const onEditProject = useCallback(
    (values, setSubmitting, projectID) => {
      makeAuthenticatedCall(`storm/projects/${projectID}`, {
        method: 'PATCH',
        body: JSON.stringify(values),
      })
        .then((response) => {
          if (response.status === 401) {
            setUserToken('');
            navigate('/login', { replace: true });
          }
          return response.json();
        })
        .then((response) => {
          if (response.success) {
            const updatedData = projects.map(p => p.id === projectID ? response.data : p);
            const {[projectID]: removedProject, ...updatedProjectsByID} = projectsByID;
            setProjects(updatedData);
            setProjectsByID(updatedProjectsByID);
          }
          setSubmitting(false);
          setCreateProjectOpen(false);
        });
    },
    [navigate, projects, projectsByID],
  )

  const getProjectFormDialogue = () => {
    switch (mode) {
      case ProjectDialogMode.NEW:
        return (
          <CreateProjectForm mode={mode} onFormSubmit={onCreateProject} goalList={goalList} withinList={withinList} kindList={kindList} typologyList={typologyList} />
        );

      case ProjectDialogMode.EDIT:
        if (!projectsByID[actionProjectID]) {
          return null;
        }
        return (
          <CreateProjectForm mode={mode} project={projectsByID[actionProjectID]} onFormSubmit={onEditProject} goalList={goalList} withinList={withinList} kindList={kindList} typologyList={typologyList} />
        );
      default:
        return null;
    }
  };

  const getMoreMenu = useCallback((projectIndex) => <ProjectMoreMenu projectID={projectIndex} isUserAdmin={user.is_admin} onEditClick={onEditClick} onDeleteClick={onDeleteProject} onViewClick={()=> {}} />, [onDeleteProject, onEditClick, user.is_admin]);

  return (
    <Page title="Projects | Storm">
      <FormDialog
        open={isCreateProjectOpen}
        handleClose={() => {
          setCreateProjectOpen(false);
        }}
        title="New Project"
      >
        {getProjectFormDialogue()}
      </FormDialog>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Projects
          </Typography>
          <Button
            onClick={() => {
              setMode(ProjectDialogMode.NEW);
              setCreateProjectOpen(true);
            }}
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
          >
            New Project
          </Button>
        </Stack>

        <Card>
          <UserListToolbar
            customerType="Project"
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
           <DataTable
              tableHead={TABLE_HEAD}
              tableData={filteredProjects}
              page={page}
              rowsPerPage={rowsPerPage}
              filterName={filterName}
              onOrderChange={onOrderChange}
              getMoreMenu={getMoreMenu}
            />
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={projects.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
