import FormBuilder, { FIELD_TYPE } from '../../../FormBuilder';

const PURCHASE_TYPE_OPTIONS = [
  { title: 'Cash Purchase', value: 1 },
  { title: 'Bank loan', value: 2 },
  { title: 'Mixed loan', value: 3 }
];
const SUSPENSIVE_CONDITIONS_OPTIONS = [
  { title: 'Building permit', value: 1 },
  { title: 'Statement', value: 2 },
  { title: 'Planning permission', value: 3 },
  { title: 'Others', value: 4 }
];

const LOAN_RATE_OPTIONS = Array(40).fill(1).map((v,i) => ({ title: (0.3 + i * 0.05).toFixed(2), value: (0.3 + i * 0.05).toFixed(2) }));
const LOAN_DURATION_OPTIONS = Array(26).fill(1).map((v,i) => ({ title: `${5 + i} years`, value: 5 + i }));

const FIELDS_CONFIG = [
  {
    type: FIELD_TYPE.SELECT,
    break: 12,
    label: 'Purchase Type',
    key: 'purchase_type',
    options: PURCHASE_TYPE_OPTIONS
  }
];

const LOAN_FIELDS_CONFIG = [
  {
    type: FIELD_TYPE.NUMBER,
    break: 12,
    label: 'Total funding amount',
    key: 'total_funding_amount'
  },
  {
    type: FIELD_TYPE.TEXT,
    break: 6,
    label: 'Source of funds',
    key: 'funds_source'
  },
  {
    type: FIELD_TYPE.SELECT,
    break: 6,
    label: 'Suspensive conditions to be provided for',
    key: 'suspensive_conditions',
    options: SUSPENSIVE_CONDITIONS_OPTIONS
  },
  {
    type: FIELD_TYPE.NUMBER,
    break: 4,
    label: 'Project contribution amount',
    key: 'project_contribution'
  },
  {
    type: FIELD_TYPE.TEXT,
    break: 4,
    label: 'Name of bank / broker',
    key: 'name_of_bank'
  },
  {
    type: FIELD_TYPE.SELECT,
    break: 4,
    label: 'Loan rate (%)',
    key: 'loan_rate',
    options: LOAN_RATE_OPTIONS
  },
  {
    type: FIELD_TYPE.EMAIL,
    break: 6,
    label: 'Bank / broker email',
    key: 'bank_email',
  },
  {
    type: FIELD_TYPE.PHONE,
    break: 6,
    label: 'Bank / broker phone',
    key: 'bank_phone',
  },
  {
    type: FIELD_TYPE.SELECT,
    break: 6,
    label: 'Term of loan',
    key: 'loan_term',
    options: LOAN_DURATION_OPTIONS,
  },
  {
    type: FIELD_TYPE.TEXT,
    break: 6,
    label: 'Loan amount',
    key: 'loan_amount',
  },
  {
    type: FIELD_TYPE.DATE,
    break: 4,
    label: 'Bank deposit date',
    key: 'bank_deposit_date',
  },
  {
    type: FIELD_TYPE.DATE,
    break: 4,
    label: 'Loan agreement date',
    key: 'loan_agreement_date',
  },
  {
    type: FIELD_TYPE.DATE,
    break: 4,
    label: 'Loan acceptance date',
    key: 'loan_acceptance_date',
  },
];
export default function CustomerProjectFundingFormFields({
  getFieldProps,
  touched,
  errors,
  values
}) {
  return (
    <>
      <FormBuilder
        fieldsConfig={FIELDS_CONFIG}
        getFieldProps={getFieldProps}
        touched={touched}
        errors={errors}
        title="Project funding"
      />
      {[2, 3].includes(values.purchase_type) && (
        <>
          <FormBuilder
            fieldsConfig={LOAN_FIELDS_CONFIG}
            getFieldProps={getFieldProps}
            touched={touched}
            errors={errors}
          />
        </>
      )}
    </>
  );
}
