import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {useState, useCallback} from 'react';
import { UserListHead } from './user';
import SearchNotFound from '../SearchNotFound';

DataTable.propTypes = {
  tableHead: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  filterName: PropTypes.string,
  onOrderChange: PropTypes.func.isRequired,
  getMoreMenu: PropTypes.func.isRequired,
}

export default function DataTable({ minWidth, tableHead, tableData, page, rowsPerPage, filterName, onOrderChange, getMoreMenu }) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');

  const getTableRow = (row) =>
  tableHead.map((col) => <col.renderer key={col.id} data={row} />);

  const handleRequestSort = useCallback((event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    onOrderChange(isAsc, property);
  }, [order, orderBy, onOrderChange]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;
  const isDataEmpty = tableData.length === 0;

  return <TableContainer sx={{ minWidth: minWidth ?? 800 }}>
  <Table>
    <UserListHead
      order={order}
      orderBy={orderBy}
      headLabel={tableHead}
      onRequestSort={handleRequestSort}
    />
    <TableBody>
      {tableData
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, idx) => {
          const { id } = row;
          return (
            <TableRow hover key={id} tabIndex={-1}>
              {getTableRow(row)}
              <TableCell align="right">
                {getMoreMenu(idx)}
              </TableCell>
            </TableRow>
          );
        })}
      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>
    {isDataEmpty && (
      <TableBody>
        <TableRow>
          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
            <SearchNotFound searchQuery={filterName} />
          </TableCell>
        </TableRow>
      </TableBody>
    )}
  </Table>
</TableContainer>
}