import FormBuilder, { FIELD_TYPE } from '../../../FormBuilder';
import {LAST_STEP_OPTIONS} from '../CustomerUtils';

const FIELDS_CONFIG = [
  {
    type: FIELD_TYPE.TEXT,
    break: 12,
    label: 'Missing parts of the file',
    key: 'missing_parts_file'
  },
  {
    type: FIELD_TYPE.SELECT,
    break: 12,
    label: 'Last step performed',
    key: 'last_step',
    options: LAST_STEP_OPTIONS
  },
  {
    type: FIELD_TYPE.TEXTAREA,
    break: 12,
    label: 'Observations',
    key: 'observations',
    rows: 20,
  }
];

export default function CustomerFileManagementFields({ getFieldProps, touched, errors }) {
  return (
    <FormBuilder
      fieldsConfig={FIELDS_CONFIG}
      title="File management"
      getFieldProps={getFieldProps}
      touched={touched}
      errors={errors}
    />
  );
}
