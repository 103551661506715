import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core';

import { PROFESSION } from '../CustomerUtils';

export default function CustomerCommonFormFields({getFieldProps, touched, errors, customerSourceList, projects}) {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          autoComplete="name"
          label="Full Name 1"
          size="small"
          {...getFieldProps('name')}
          error={Boolean(touched.name && errors.name)}
          helperText={touched.name && errors.name}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          autoComplete="name"
          label="Full Name 2"
          size="small"
          {...getFieldProps('name_additional')}
          error={Boolean(touched.name_additional && errors.name_additional)}
          helperText={touched.name_additional && errors.name_additional}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          autoComplete="phone"
          label="Phone 1"
          size="small"
          {...getFieldProps('phone')}
          error={Boolean(touched.phone && errors.phone)}
          helperText={touched.phone && errors.phone}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          autoComplete="phone"
          label="Phone 2"
          size="small"
          {...getFieldProps('phone_additional')}
          error={Boolean(touched.phone_additional && errors.phone_additional)}
          helperText={touched.phone_additional && errors.phone_additional}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          autoComplete="email"
          label="Email 1"
          size="small"
          {...getFieldProps('email')}
          error={Boolean(touched.email && errors.email)}
          helperText={touched.email && errors.email}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          autoComplete="email"
          label="Email 2"
          size="small"
          {...getFieldProps('email_additional')}
          error={Boolean(touched.email_additional && errors.email_additional)}
          helperText={touched.email_additional && errors.email_additional}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel id="select-prof-label">Profession 1</InputLabel>
          <Select size="small" labelId="select-prof-label" {...getFieldProps('profession')}>
            {PROFESSION.map((prof) => (
              <MenuItem key={prof.value} value={prof.value}>
                {prof.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel id="select-profadd-label">Profession 2</InputLabel>
          <Select
            size="small"
            labelId="select-profadd-label"
            {...getFieldProps('profession_additional')}
          >
            {PROFESSION.map((prof) => (
              <MenuItem key={prof.value} value={prof.value}>
                {prof.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Date of Birth 1"
          size="small"
          type="date"
          {...getFieldProps('date_of_birth')}
          error={Boolean(touched.date_of_birth && errors.date_of_birth)}
          helperText={touched.date_of_birth && errors.date_of_birth}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          autoComplete="email"
          label="Date of Birth 2"
          size="small"
          type="date"
          {...getFieldProps('date_of_birth_additional')}
          error={Boolean(touched.date_of_birth_additional && errors.date_of_birth_additional)}
          helperText={touched.date_of_birth_additional && errors.date_of_birth_additional}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Place of Birth 1"
          size="small"
          {...getFieldProps('place_of_birth')}
          error={Boolean(touched.place_of_birth && errors.place_of_birth)}
          helperText={touched.place_of_birth && errors.place_of_birth}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Place of Birth 2"
          size="small"
          {...getFieldProps('place_of_birth_additional')}
          error={Boolean(touched.place_of_birth_additional && errors.place_of_birth_additional)}
          helperText={touched.place_of_birth_additional && errors.place_of_birth_additional}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Nationality"
          size="small"
          {...getFieldProps('nationality')}
          error={Boolean(touched.nationality && errors.nationality)}
          helperText={touched.nationality && errors.nationality}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          autoComplete="email"
          label="Nationality 2"
          size="small"
          {...getFieldProps('nationality_additional')}
          error={Boolean(touched.nationality_additional && errors.nationality_additional)}
          helperText={touched.nationality_additional && errors.nationality_additional}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Postal Code"
          size="small"
          {...getFieldProps('postal_code')}
          error={Boolean(touched.postal_code && errors.postal_code)}
          helperText={touched.postal_code && errors.postal_code}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel id="select-source-label">Contact Source</InputLabel>
          <Select size="small" labelId="select-source-label" {...getFieldProps('customer_source')}>
            {customerSourceList.map((source) => (
              <MenuItem key={source[1]} value={source[1]}>
                {source[0]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          fullWidth
          multiline
          rows={3}
          label="Address of buyers"
          size="small"
          {...getFieldProps('address_of_buyers')}
          error={Boolean(touched.address_of_buyers && errors.address_of_buyers)}
          helperText={touched.address_of_buyers && errors.address_of_buyers}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormControl fullWidth>
          <InputLabel id="select-project-label">Projects</InputLabel>
          <Select
            size="small"
            multiple
            labelId="select-project-label"
            {...getFieldProps('projects')}
          >
            {projects.map((project) => (
              <MenuItem key={project.id} value={project.id}>
                {project.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
}
