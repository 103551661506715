import { StringRenderer, DateRenderer, StatusRenderer, PercentRenderer } from '../renderers';
import { CustomerType, CLIENT_LAST_STEP } from './CustomerUtils';

const CUSTOMER_LIST_COLS = [
  'status',
  'name',
  'incharge_first_name',
  'created_first_name',
  'updated_first_name',
  'projects',
  'customer_source',
  'created_on',
  'last_action',
  'last_action_date'
];

const CLIENT_LIST_COLS = [
  'incharge_first_name',
  'case_percent',
  'last_step_performed',
  'seller',
  'seller_additional',
  'buyer',
  'buyer_additional',
  'turnover',
  'agency_ht',
  'total_vat',
  'nego_ht',
  'last_action_date'
];

const TABLE_HEAD = {
  status: { id: 'status', label: 'customer.status' },
  name: { id: 'name', label: 'customer.name' },
  incharge_first_name: {
    id: 'incharge_first_name',
    label: 'customer.incharge_first_name'
  },
  created_first_name: {
    id: 'created_first_name',
    label: 'customer.created_first_name'
  },
  updated_first_name: {
    id: 'updated_first_name',
    label: 'customer.updated_first_name'
  },
  projects: {
    id: 'projects',
    label: 'customer.projects'
  },
  customer_source: {
    id: 'customer_source',
    label: 'customer.customer_source'
  },
  created_on: {
    id: 'created_on',
    label: 'customer.created_on'
  },
  last_action: {
    id: 'last_action',
    label: 'customer.last_action'
  },
  last_action_date: {
    id: 'last_action_date',
    label: 'customer.last_action_date'
  },
  case_percent: { id: 'case_percent', label: 'customer.case_percent' },
  last_step_performed: { id: 'last_step_performed', label: 'customer.last_step_performed' },
  seller: { id: 'seller', label: 'customer.seller' },
  seller_additional: { id: 'seller_additional', label: 'customer.seller_additional' },
  buyer: { id: 'buyer', label: 'customer.buyer' },
  buyer_additional: { id: 'buyer_additional', label: 'customer.buyer_additional' },
  turnover: { id: 'turnover', label: 'customer.turnover' },
  agency_ht: { id: 'agency_ht', label: 'customer.agency_ht' },
  total_vat: { id: 'total_vat', label: 'customer.total_vat' },
  nego_ht: { id: 'nego_ht', label: 'customer.nego_ht' }
};

function getTableRenders({ projectsMap, customerSourceList, actions }) {
  return {
    status: ({ data }) => <StatusRenderer value={data.last_updated} />,
    name: ({ data }) => <StringRenderer value={data.name} />,
    incharge_first_name: ({ data }) => <StringRenderer value={data.incharge_first_name} />,
    created_first_name: ({ data }) => <StringRenderer value={data.created_first_name} />,
    updated_first_name: ({ data }) => <StringRenderer value={data.updated_first_name} />,
    projects: ({ data }) => {
      let value;
      if (projectsMap && data.projects) {
        value = data.projects
          .split(',')
          .map((id) => projectsMap[id]?.name)
          .join(', ');
      }
      return <StringRenderer value={value} />;
    },
    customer_source: ({ data }) => {
      let value;
      if (customerSourceList && data.customer_source) {
        [value] = customerSourceList.find((d) => d[1] === data.customer_source) || [''];
      }
      return <StringRenderer value={value} />;
    },
    created_on: ({ data }) => <DateRenderer value={data.created_on} />,
    last_action: ({ data }) => {
      let value;
      if (actions && data.last_action) {
        [value] = actions.find((d) => d[1] === parseInt(data.last_action, 10)) || [''];
      }
      return <StringRenderer value={value} />;
    },
    last_action_date: ({ data }) => <DateRenderer value={data.last_action_date} />,
    case_percent: ({ data }) => <PercentRenderer value={data.last_step} />,
    last_step_performed: ({ data }) => (
      <StringRenderer value={data.last_step ? CLIENT_LAST_STEP[data.last_step] : null} />
    ),
    seller: ({ data }) => <StringRenderer value={data.vendor_name} />,
    seller_additional: ({ data }) => <StringRenderer value={data.vendor_name_additional} />,
    buyer: ({ data }) => <StringRenderer value={data.name} />,
    buyer_additional: ({ data }) => <StringRenderer value={data.name_additional} />,
    turnover: ({ data }) => <StringRenderer value={data.agency_ttc} />,
    agency_ht: ({ data }) => <StringRenderer value="--" />,
    total_vat: ({ data }) => <StringRenderer value="--" />,
    nego_ht: ({ data }) => <StringRenderer value="--" />
  };
}

export function getTableConfig(type, data) {
  const renderers = getTableRenders(data);
  const colList = type === CustomerType.CLIENT ? CLIENT_LIST_COLS : CUSTOMER_LIST_COLS;
  return colList.map((val) => ({ ...TABLE_HEAD[val], renderer: renderers[val] }));
}
