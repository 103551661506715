import FormBuilder, { FIELD_TYPE } from '../../../FormBuilder';

const MANDATE_TYPES = [
  {'title': 'Simple Mandate', value:1 },
  {'title': 'Exclusive Mandate', value:2 },
];

const AGENCY_FEES_TYPES = [
  {'title': 'Vendor Charge', value:1 },
  {'title': 'Acquirer Charge', value:2 },
];

const FIELDS_CONFIG = [
  {
    type: FIELD_TYPE.NUMBER,
    break: 6,
    label: 'Net seller selling price',
    key: 'net_selling_price'
  },
  {
    type: FIELD_TYPE.NUMBER,
    break: 6,
    label: 'HAI sale price',
    key: 'hai_sale_price'
  },
  {
    type: FIELD_TYPE.TEXT,
    break: 6,
    label: 'Sales / research mandate number',
    key: 'sales_mandate_number'
  },
  {
    type: FIELD_TYPE.NUMBER,
    break: 6,
    label: 'Security Deposit',
    key: 'security_deposit'
  },
  {
    type: FIELD_TYPE.SELECT,
    break: 6,
    label: 'Type of Mandate',
    key: 'mandate',
    options: MANDATE_TYPES,
  },
  {
    type: FIELD_TYPE.SELECT,
    break: 6,
    label: 'Agency fees payable',
    key: 'agency_fees',
    options: AGENCY_FEES_TYPES,
  },
];

export default function CustomerFinancialInfoFields({ getFieldProps, touched, errors }) {
  return <FormBuilder fieldsConfig={FIELDS_CONFIG} title="Financial elements of the transaction" getFieldProps={getFieldProps} touched={touched} errors={errors} />;
}
