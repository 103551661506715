import { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import UserContext from '../../context/UserContext';
import TypeContext from '../../context/TypeContext';
import { makeAuthenticatedCall } from '../../api/api';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({});
  const [types, setTypes] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchUserDetails() {
      return makeAuthenticatedCall('storm/me')
        .then((response) => {
          if (response.status === 401) {
            navigate('/login', { replace: true });
            throw new Error("Invalid login credentials");
          }
          return response.json();
        })
        .then((response) => response.data);
    }

    async function fetchAppTypes() {
      return makeAuthenticatedCall('storm/types')
        .then((response) => response.json())
        .then((response) => response.data);
    }

    Promise.all([fetchUserDetails(), fetchAppTypes()])
      .then(([user, types]) => {
        setUser(user);
        setTypes(types);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [navigate]);

  return (
    <RootStyle>
      <UserContext.Provider value={user}>
        <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
        <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        <MainStyle>
          <TypeContext.Provider value={types}>
            <Outlet />
          </TypeContext.Provider>
        </MainStyle>
      </UserContext.Provider>
    </RootStyle>
  );
}
