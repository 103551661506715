// material
import { Grid, Container } from '@material-ui/core';
// components
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Page from '../components/Page';
import {
  AppNewCustomers
} from '../components/_dashboard/app';
import { makeAuthenticatedCall } from '../api/api';
import { setUserToken } from '../utils/localStorage';


export default function DashboardApp() {
  const navigate = useNavigate();
  const [data, setData] = useState({});

  useEffect(() => {
    makeAuthenticatedCall(`storm/dashboard`)
      .then((response) => {
        if (response.status === 401) {
          setUserToken('');
          navigate('/login', { replace: true });
        }
        return response.json();
      })
      .then((response) => {
        if (response.data) {
          setData(response.data);
        }
      });
  }, [navigate]);

  return (
    <Page title="Dashboard | Storm">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewCustomers title="Projects" value={data.Projects} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewCustomers title="Suspects" value={data.Suspect} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewCustomers title="Prospects" value={data.Prospect} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewCustomers title="Clients" value={data.Client} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
