import {
  Grid,
  Typography,
  Stack,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  TextareaAutosize
} from '@material-ui/core';

export const FIELD_TYPE = {
  TEXT: 'text',
  NUMBER: 'number',
  SELECT: 'select',
  EMAIL: 'email',
  PHONE: 'phone',
  DATE: 'date',
  TEXTAREA: 'textarea'
};

export default function FormBuilder({ fieldsConfig, title, getFieldProps, touched, errors }) {
  return (
    <>
      {title && (
        <Grid item xs={12}>
          <Stack direction="column" alignItems="center" spacing={2}>
            <Typography variant="body1">{title}</Typography>
          </Stack>
        </Grid>
      )}
      {fieldsConfig.map((field) => {
        switch (field.type) {
          case FIELD_TYPE.TEXT:
          case FIELD_TYPE.NUMBER:
          case FIELD_TYPE.PHONE:
          case FIELD_TYPE.EMAIL:
          case FIELD_TYPE.DATE:
            return (
              <Grid key={field.key} item xs={12} sm={field.break}>
                <TextField
                  fullWidth
                  label={field.label}
                  type={field.type}
                  size="small"
                  {...getFieldProps(field.key)}
                  error={Boolean(touched[field.key] && errors[field.key])}
                  helperText={touched[field.key] && errors[field.key]}
                />
              </Grid>
            );
          case FIELD_TYPE.SELECT:
            return (
              <Grid key={field.key} item xs={12} sm={field.break}>
                <FormControl fullWidth>
                  <InputLabel id={`select-${field.key}-label`}>{field.label}</InputLabel>
                  <Select
                    size="small"
                    labelId={`select-${field.key}-label`}
                    {...getFieldProps(field.key)}
                  >
                    {field.options.map((m) => (
                      <MenuItem key={m.value} value={m.value} disabled={m.disabled ?? false}>
                        {m.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            );
          case FIELD_TYPE.TEXTAREA:
            return (
              <Grid key={field.key} item xs={12} sm={field.break}>
                <InputLabel id={`textarea-${field.key}-label`}>{field.label}</InputLabel>
                <TextareaAutosize
                  minRows={field.rows ?? 5}
                  labelId={`textarea-${field.key}-label`}
                  style={{ width: '100%' }}
                  {...getFieldProps(field.key)}
                />
              </Grid>
            );
          default:
        }
        return null;
      })}
    </>
  );
}
