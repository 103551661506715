import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { useState } from 'react';
// material
import {
  InputLabel,
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';

// ----------------------------------------------------------------------

export default function CreateProjectForm({
  onFormSubmit,
  goalList,
  withinList,
  kindList,
  typologyList,
  mode,
  project
}) {
  const alterChk = {
    accession_yes: 'accession_no',
    accession_no: 'accession_yes',
    financing_yes: 'financing_no',
    financing_no: 'financing_yes'
  };

  const fieldToCheck = {
    accession: ['accession_no', 'accession_yes'],
    bank_seen: ['financing_no', 'financing_yes']
  };

  const ProjectSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    min_area: Yup.number(),
    min_budget: Yup.number(),
    max_budget: Yup.number(),
    within: Yup.number(),
    goal: Yup.number(),
    comments: Yup.string(),
    kinds: Yup.array(),
    typologies: Yup.array(),
    accession: Yup.bool().nullable(),
    bank_seen: Yup.bool().nullable(),
    purchase: Yup.number(),
    duration: Yup.number(),
    loan_amount: Yup.number(),
    project_contribution: Yup.number(),
    address_1: Yup.string(),
    address_2: Yup.string(),
    address_3: Yup.string(),
    tax_income: Yup.number()
  });

  const purchaseList = [
    ['Cash', 1],
    ['Ready', 2],
    ['Mixed', 3]
  ];

  const durationList = [
    ['5 years', 1],
    ['6 years', 2]
  ];
  let initialValues = {
    name: '',
    min_area: 15,
    min_budget: '',
    max_budget: '',
    within: 1,
    goal: 1,
    comments: '',
    kinds: [],
    typologies: [],
    accession: null,
    bank_seen: null,
    purchase: 1,
    duration: 1,
    loan_amount: '',
    project_contribution: '',
    address_1: '',
    address_2: '',
    address_3: '',
    tax_income: ''
  };
  const multiField = ['kinds', 'typologies'];
  const initialChkState = {
    accession_yes: false,
    accession_no: false,
    financing_yes: false,
    financing_no: false,
  };

  if (mode === 3 && project != null) {
    initialValues = Object.keys(initialValues).reduce(
      (prev, currKey) => ({
        ...prev,
        [currKey]: project[currKey] ?? initialValues[currKey]
      }),
      {}
    );

    multiField.forEach((field) => {
      if (initialValues[field] && !Array.isArray(initialValues[field])) {
        initialValues[field] = initialValues[field].split(',');
      }
    });
    const checkState = {};
    Object.keys(fieldToCheck).forEach((field) => {
      if (project[field] === 0) {
        checkState[fieldToCheck[field][0]] = true;
      } else if (project[field] === 1) {
        checkState[fieldToCheck[field][1]] = true;
      }
    });
    Object.assign(initialChkState, checkState);
  }

  const [chkBoxState, setChkBoxState] = useState(initialChkState);

  const formik = useFormik({
    initialValues,
    validationSchema: ProjectSchema,
    onSubmit: (values) => {
      const submitValues = {
        ...values,
        accession: !(chkBoxState.accession_yes || chkBoxState.accession_no)
          ? null
          : chkBoxState.accession_yes,
        bank_seen: !(chkBoxState.financing_yes || chkBoxState.financing_no)
          ? null
          : chkBoxState.financing_yes
      };
      if (values.purchase === 1) {
        submitValues.duration = 1;
        submitValues.loan_amount = '';
        submitValues.project_contribution = '';
      }
      onFormSubmit(submitValues, setSubmitting, project ? project.id: null);
    }
  });

  const handleCheckBoxChange = (event) => {
    let newChkBoxState = {
      ...chkBoxState,
      [event.target.name]: event.target.checked
    };
    if (alterChk[event.target.name]) {
      newChkBoxState = {
        ...newChkBoxState,
        [alterChk[event.target.name]]: !event.target.checked
      };
    }
    setChkBoxState(newChkBoxState);
  };

  const { errors, touched, values, isSubmitting, setSubmitting, handleSubmit, getFieldProps } =
    formik;
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              autoComplete="name"
              label="Name"
              size="small"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="select-goal-label">Goal</InputLabel>
              <Select size="small" labelId="select-goal-label" {...getFieldProps('goal')}>
                {goalList.map((goal) => (
                  <MenuItem key={goal[1]} value={goal[1]}>
                    {goal[0]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <InputLabel id="select-kind-label">Kind</InputLabel>
              <Select size="small" multiple labelId="select-kind-label" {...getFieldProps('kinds')}>
                {kindList.map((kind) => (
                  <MenuItem key={kind[1]} value={kind[1]}>
                    {kind[0]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <InputLabel id="select-typology-label">Typology</InputLabel>
              <Select
                size="small"
                multiple
                labelId="select-typology-label"
                {...getFieldProps('typologies')}
              >
                {typologyList.map((typology) => (
                  <MenuItem key={typology[1]} value={typology[1]}>
                    {typology[0]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <InputLabel id="select-area-label">Minimum Area</InputLabel>
              <Select size="small" labelId="select-area-label" {...getFieldProps('min_area')}>
                {Array.from(Array(20).keys()).map((index) => (
                  <MenuItem key={index} value={(index + 1) * 15}>
                    {(index + 1) * 15}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <InputLabel id="select-within-label">Within</InputLabel>
              <Select size="small" labelId="select-within-label" {...getFieldProps('within')}>
                {withinList.map((within) => (
                  <MenuItem key={within[1]} value={within[1]}>
                    {within[0]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Min Budget"
              type="number"
              size="small"
              {...getFieldProps('min_budget')}
              error={Boolean(touched.min_budget && errors.min_budget)}
              helperText={touched.min_budget && errors.min_budget}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Max Budget"
              type="number"
              size="small"
              {...getFieldProps('max_budget')}
              error={Boolean(touched.max_budget && errors.max_budget)}
              helperText={touched.max_budget && errors.max_budget}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <InputLabel id="select-purchase-label">Purchase</InputLabel>
              <Select size="small" labelId="select-purchase-label" {...getFieldProps('purchase')}>
                {purchaseList.map((purchase) => (
                  <MenuItem key={purchase[1]} value={purchase[1]}>
                    {purchase[0]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {values && values.purchase !== 1 && (
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="select-duration-label">Duration</InputLabel>
                <Select size="small" labelId="select-duration-label" {...getFieldProps('duration')}>
                  {durationList.map((duration) => (
                    <MenuItem key={duration[1]} value={duration[1]}>
                      {duration[0]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {values && values.purchase !== 1 && (
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Loan Amount"
                type="number"
                size="small"
                {...getFieldProps('loan_amount')}
              />
            </Grid>
          )}
          {values && values.purchase !== 1 && (
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Project Contributions"
                type="number"
                size="small"
                {...getFieldProps('project_contribution')}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="Address 1" size="small" {...getFieldProps('address_1')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="Address 2" size="small" {...getFieldProps('address_2')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField fullWidth label="Address 3" size="small" {...getFieldProps('address_3')} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Tax Income"
              size="small"
              type="number"
              {...getFieldProps('tax_income')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div>Primo - Accession</div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={chkBoxState.accession_yes}
                  onChange={handleCheckBoxChange}
                  name="accession_yes"
                />
              }
              label="Yes"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={chkBoxState.accession_no}
                  onChange={handleCheckBoxChange}
                  name="accession_no"
                />
              }
              label="No"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div>Financing - have you ever seen the bank?</div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={chkBoxState.financing_yes}
                  onChange={handleCheckBoxChange}
                  name="financing_yes"
                />
              }
              label="Yes"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={chkBoxState.financing_no}
                  onChange={handleCheckBoxChange}
                  name="financing_no"
                />
              }
              label="No"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Comments"
              type="string"
              size="small"
              multiline
              rows={8}
              {...getFieldProps('comments')}
              error={Boolean(touched.comments && errors.comments)}
              helperText={touched.comments && errors.comments}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
