import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Grid, MenuItem, TextareaAutosize, Select, FormControl, InputLabel } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';

// ----------------------------------------------------------------------

export default function AddActionForm({ onFormSubmit, actionList }) {
  const ActionSchema = Yup.object().shape({
    action_type: Yup.number().required('Action is Required'),
    comment: Yup.string()
  });

  const formik = useFormik({
    initialValues: {
      action_type: '',
      comment: ''
    },
    validationSchema: ActionSchema,
    onSubmit: (values) => {
      onFormSubmit(values, setSubmitting);
    }
  });

  const { errors, touched, isSubmitting, setSubmitting, handleSubmit, getFieldProps } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
            <InputLabel id="select-action-label">Action</InputLabel>
              <Select
                  labelId="select-action-label"
                {...getFieldProps('action_type')}
                error={Boolean(touched.action_type && errors.action_type)}
              >
                {
                  actionList.map((action) => (<MenuItem key={action[1]} value={action[1]}>{action[0]}</MenuItem>))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <InputLabel id="comment-action-label">Comment</InputLabel>
            <TextareaAutosize
              minRows={8}
              labelId="comment-action-label"
              style={{ width: '100%' }}
              {...getFieldProps('comment')}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
