import { Grid, TextField } from '@material-ui/core';
import FormBuilder, { FIELD_TYPE } from '../../../FormBuilder';

const VAT_TYPES = [
  { title: '8.5', value: 1 },
  { title: '20', value: 2 }
];

const NEGO_RATE_TYPES = [
  { title: '0', value: 0 },
  { title: '50', value: 50 },
  { title: '55', value: 55 },
  { title: '60', value: 60 },
  { title: '65', value: 65 },
  { title: '70', value: 70 },
  { title: '75', value: 75 }
];

const INTERAGENCY_TYPES = [
  { title: 'Yes', value: 1 },
  { title: 'No', value: 2 }
];

const HONO_INTERAGENCY_TYPES = [
  { title: 'N/A', value: 1 },
  { title: '50/50', value: 2 },
  { title: '55/45', value: 3 },
  { title: '60/40', value: 4 },

];

const FIELDS_CONFIG = [
  {
    type: FIELD_TYPE.NUMBER,
    break: 6,
    label: 'Hono. Agency TTC',
    key: 'agency_ttc'
  },
  {
    type: FIELD_TYPE.SELECT,
    break: 6,
    label: 'VAT value',
    key: 'fees_vat',
    options: VAT_TYPES
  },
  {
    type: FIELD_TYPE.SELECT,
    break: 6,
    label: 'Nego rate. (%)',
    key: 'fees_rate',
    options: NEGO_RATE_TYPES
  }
];

const INTERAGENCY_FIELDS_CONFIG = [
  {
    type: FIELD_TYPE.SELECT,
    break: 6,
    label: 'Interagency ?',
    key: 'interagency',
    options: INTERAGENCY_TYPES
  },
  {
    type: FIELD_TYPE.TEXT,
    break: 6,
    label: 'Interagency Name',
    key: 'interagency_name'
  },
  {
    type: FIELD_TYPE.SELECT,
    break: 6,
    label: 'Hono. Interagency',
    key: 'hono_interagency',
    options: HONO_INTERAGENCY_TYPES,
  },
];

export default function CustomerFeesFields({ getFieldProps, touched, errors }) {
  return (
    <>
      <FormBuilder
        fieldsConfig={FIELDS_CONFIG}
        title="Our fees"
        getFieldProps={getFieldProps}
        touched={touched}
        errors={errors}
      />
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Hono. Agency HT"
          size="small"
          value="0"
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Hono. Nego. HT"
          size="small"
          value="0"
          disabled
        />
      </Grid>
      <FormBuilder
        fieldsConfig={INTERAGENCY_FIELDS_CONFIG}
        getFieldProps={getFieldProps}
        touched={touched}
        errors={errors}
      />
       <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Hono. Final Storm HT"
          size="small"
          value="0"
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Total VAT Agency"
          size="small"
          value="0"
          disabled
        />
      </Grid>
    </>
  );
}
