import { TableCell } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const StatusCircle = styled('div')(({ theme }) => ({
  height: '25px',
  width: '25px',
  backgroundColor: '#bbb',
  borderRadius: '50%',
  display: 'inline-block'
}));

const StyledPercentContainer = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'relative',
  textAlign: 'center',
}));

const StyledPercent =  styled('div')(({ theme, value }) => ({
  // eslint-disable-next-line no-nested-ternary
  backgroundColor: value < 30 ? 'red' : (value < 50 ? 'orange' : 'green'),
  width: `${value}%`,
  height: '100%',
  position: 'absolute',
  zIndex: '-1',
}));

export function NumberRenderer({ value }) {
  return <TableCell>{value ? value.toLocaleString() : '-'}</TableCell>;
}

export function StringRenderer({ value }) {
  return <TableCell>{value || '-'}</TableCell>;
}

export function StatusRenderer({ value }) {
  if (!value) {
    return <StringRenderer value />;
  }
  const valDate = new Date(value);
  const currDate = new Date();
  const diffTime = Math.abs(currDate - valDate);
  const diffDays = Math.ceil(diffTime / (1000 * 3600 * 24));
  let color = 'green';
  if (diffDays > 3 && diffDays <= 6) {
    color = 'orange';
  }
  if (diffDays > 6) {
    color = 'red';
  }

  return (
    <TableCell>
      <StatusCircle style={{ backgroundColor: [color] }} />
    </TableCell>
  );
}

export function DateRenderer({ value }) {
  const valDate = value ? new Date(value).toLocaleString() : '-';
  return <TableCell>{valDate}</TableCell>;
}

export function PercentRenderer({ value }) {
  if (!value) {
    value = 0;
  }

  return (
    <TableCell>
    <StyledPercentContainer>
      <StyledPercent value={value} />
      <div>{value} %</div>
    </StyledPercentContainer>
    </TableCell>
  );
}
