import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState, useCallback, useEffect, useContext, useMemo } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TablePagination,
  Snackbar
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// components
import { CustomerType, getCustomerType } from '../components/_dashboard/customers/CustomerUtils';
import CustomerMoreMenu from '../components/_dashboard/customers/CustomerMoreMenu';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import DataTable from '../components/_dashboard/DataTable';
import FormDialog from '../components/FormDialog';
import AddActionForm from '../components/_dashboard/customers/AddActionForm';
import TypeContext from '../context/TypeContext';
import CreateCustomerForm from '../components/_dashboard/customers/CreateCustomerForm';
import CustomerDetails from '../components/_dashboard/customers/CustomerDetails';
import { UserListToolbar } from '../components/_dashboard/user';
import { getTableConfig } from '../components/_dashboard/customers/CustomerTableConfig';
import { makeAuthenticatedCall } from '../api/api';
import { setUserToken } from '../utils/localStorage';
import UserContext from '../context/UserContext';


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const CustomerDialogMode = {
  NEW: 1,
  VIEW: 2,
  EDIT: 3
};

export default function Customer({ type }) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isCustomerDialogueOpen, setCustomerDialogueOpen] = useState(false);
  const [isAddActionOpen, setAddActionOpen] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [actionCustomerID, setActionCustomerID] = useState(-1);
  const [isSnackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [mode, setMode] = useState(CustomerDialogMode.NEW);
  const [customerByID, setCustomerByID] = useState({});
  const navigate = useNavigate();
  const types = useContext(TypeContext);
  const user = useContext(UserContext);
  const actions = useMemo(() => types.actions || [], [types]);
  const { t } = useTranslation();
  const customerSourceList = useMemo(() => types.customer_sources || [], [types]);
  useEffect(() => {
    makeAuthenticatedCall(`storm/customers?type=${getCustomerType(type)}`)
      .then((response) => {
        if (response.status === 401) {
          setUserToken('');
          navigate('/login', { replace: true });
        }
        return response.json();
      })
      .then((response) => {
        if (response.data) {
          setCustomers(response.data);
          setPage(0);
        }
      });
  }, [navigate, type]);

  useEffect(() => {
    makeAuthenticatedCall(`storm/projects?cols=id,name`)
      .then((response) => {
        if (response.status === 401) {
          setUserToken('');
          navigate('/login', { replace: true });
        }
        return response.json();
      })
      .then((response) => {
        if (response.data) {
          setProjects(response.data);
        }
      });
  }, [navigate, setProjects]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const filteredCustomers = applySortFilter(customers, getComparator(order, orderBy), filterName);
  const onOrderChange = useCallback(
    (isAsc, property) => {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [setOrder, setOrderBy]
  );

  const onCreateCustomer = useCallback(
    (values, setSubmitting) => {
      makeAuthenticatedCall('storm/customers', { method: 'POST', body: JSON.stringify(values) })
        .then((response) => {
          setSubmitting(false);
          setCustomerDialogueOpen(false);
          if (response.status === 401) {
            setUserToken('');
            navigate('/login', { replace: true });
          }
          return response.json();
        })
        .then((response) => {
          if (response) {
            setCustomers([...customers, response]);
            setSnackMessage(`New ${type} added!`);
            setSnackOpen(true);
          }
        });
    },
    [navigate, customers, type]
  );

  const onEditCustomer = useCallback(
    (values, setSubmitting, customerID) => {
      setSubmitting(false);
      setCustomerDialogueOpen(false);
      makeAuthenticatedCall(`storm/customers/${customerID}`, {
        method: 'PATCH',
        body: JSON.stringify(values)
      })
        .then((response) => {
          if (response.status === 401) {
            setUserToken('');
            navigate('/login', { replace: true });
          }
          return response.json();
        })
        .then((data) => {
          // eslint-disable-next-line no-unused-vars
          const { [customerID]: removedCustomer, ...custByID } = customerByID;
          setCustomers(customers.map((cust) => (cust.id === customerID ? data : cust)));
          setCustomerByID(custByID);
          setSnackMessage(`${removedCustomer.name} successfully updated`);
          setSnackOpen(true);
        });
    },
    [navigate, customerByID, customers]
  );

  const onAddActionMenuClick = useCallback(
    (customerIndex) => {
      const idx = page * rowsPerPage + customerIndex;
      setActionCustomerID(filteredCustomers[idx].id);
      setAddActionOpen(true);
    },
    [filteredCustomers, page, rowsPerPage]
  );

  const onAddAction = useCallback(
    (values, setSubmitting) => {
      makeAuthenticatedCall(`storm/customers/${actionCustomerID}/actions`, {
        method: 'POST',
        body: JSON.stringify(values)
      })
        .then((response) => {
          setSubmitting(false);
          setAddActionOpen(false);
          if (response.status === 401) {
            setUserToken('');
            navigate('/login', { replace: true });
          }
          return response.json();
        })
        .then((response) => {
          if (response.success) {
            setCustomers(
              customers.map((c) =>
                c.id === actionCustomerID
                  ? { ...c, last_action: values.action_type, last_action_date: new Date() }
                  : c
              )
            );
            setSnackMessage(`Action successfully registered`);
            setSnackOpen(true);
          }
        });
    },
    [actionCustomerID, navigate, customers]
  );

  const onDeleteClick = useCallback(
    (customerIndex) => {
      const idx = page * rowsPerPage + customerIndex;
      const custID = filteredCustomers[idx].id;
      makeAuthenticatedCall(`storm/customers/${custID}`, {
        method: 'DELETE'
      })
        .then((response) => {
          if (response.status === 401) {
            setUserToken('');
            navigate('/login', { replace: true });
          }
          return response.json();
        })
        .then((response) => {
          if (response.success) {
            setCustomers(customers.filter((c) => c.id !== custID));
            setSnackMessage(`${type} successfully deleted`);
            setSnackOpen(true);
          }
        });
    },
    [customers, navigate, page, rowsPerPage, filteredCustomers, type]
  );

  const handleSnackClose = useCallback(() => {
    setSnackOpen(false);
  }, [setSnackOpen]);

  const fetchCustomer = useCallback(
    (customerID) => {
      makeAuthenticatedCall(`storm/customers/${customerID}`)
        .then((response) => {
          if (response.status === 401) {
            setUserToken('');
            navigate('/login', { replace: true });
          }
          return response.json();
        })
        .then((response) => {
          if (response.success) {
            setCustomerByID({
              ...customerByID,
              [customerID]: response.data
            });
          }
        });
    },
    [navigate, customerByID]
  );

  const onViewClick = useCallback(
    (customerIndex) => {
      const idx = page * rowsPerPage + customerIndex;
      const customerID = filteredCustomers[idx].id;
      setMode(CustomerDialogMode.VIEW);
      setActionCustomerID(customerID);
      setCustomerDialogueOpen(true);

      if (customerByID[customerID]) {
        return;
      }

      fetchCustomer(customerID);
    },
    [page, rowsPerPage, fetchCustomer, filteredCustomers, customerByID]
  );

  const onEditClick = useCallback(
    (customerIndex) => {
      const idx = page * rowsPerPage + customerIndex;
      const customerID = filteredCustomers[idx].id;
      setMode(CustomerDialogMode.EDIT);
      setActionCustomerID(customerID);
      setCustomerDialogueOpen(true);

      if (customerByID[customerID]) {
        return;
      }

      fetchCustomer(customerID);
    },
    [page, rowsPerPage, fetchCustomer, customerByID, filteredCustomers]
  );

  const onChangeStatusClick = useCallback(
    (customerIndex, type) => {
      let newType = 1;
      switch (type) {
        case CustomerType.SUSPECT:
          newType = getCustomerType(CustomerType.PROSPECT);
          break;
        case CustomerType.PROSPECT:
          newType = getCustomerType(CustomerType.CLIENT);
          break;
        default:
      }
      const idx = page * rowsPerPage + customerIndex;
      const customerID = filteredCustomers[idx].id;
      const requestData = { type: newType };

      makeAuthenticatedCall(`storm/customers/${customerID}`, {
        method: 'PATCH',
        body: JSON.stringify(requestData)
      })
        .then((response) => {
          if (response.status === 401) {
            setUserToken('');
            navigate('/login', { replace: true });
          }
          return response.json();
        })
        .then(() => {});
    },
    [filteredCustomers, page, rowsPerPage, navigate]
  );

  const getMoreMenu = useCallback(
    (customerIndex) => (
      <CustomerMoreMenu
        customerID={customerIndex}
        isAdminUser={user.is_admin}
        type={type}
        onAddActionClick={onAddActionMenuClick}
        onDeleteClick={onDeleteClick}
        onViewClick={onViewClick}
        onEditClick={onEditClick}
        onChangeStatusClick={onChangeStatusClick}
      />
    ),
    [
      user.is_admin,
      type,
      onAddActionMenuClick,
      onDeleteClick,
      onViewClick,
      onEditClick,
      onChangeStatusClick
    ]
  );

  const getCustomerFormDialogue = () => {
    switch (mode) {
      case CustomerDialogMode.NEW:
        return (
          <CreateCustomerForm
            onFormSubmit={onCreateCustomer}
            type={getCustomerType(type)}
            mode={CustomerDialogMode.NEW}
            customerSourceList={customerSourceList}
            projects={projects}
          />
        );
      case CustomerDialogMode.VIEW:
        return <CustomerDetails type={type} customer={customerByID[actionCustomerID]} />;
      case CustomerDialogMode.EDIT:
        if (!customerByID[actionCustomerID]) {
          return null;
        }
        return (
          <CreateCustomerForm
            onFormSubmit={onEditCustomer}
            type={type}
            mode={CustomerDialogMode.EDIT}
            customer={customerByID[actionCustomerID]}
            customerSourceList={customerSourceList}
            projects={projects}
          />
        );
      default:
        return null;
    }
  };

  const projectsMap = useMemo(
    () => projects.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {}),
    [projects]
  );
  const tableConfig = useMemo(
    () => getTableConfig(type, { projectsMap, customerSourceList, actions }),
    [type, projectsMap, customerSourceList, actions]
  );

  const dialogueTitle = useMemo(() => {
    const pre =
      // eslint-disable-next-line no-nested-ternary
      mode === CustomerDialogMode.NEW ? 'new' : mode === CustomerDialogMode.EDIT ? 'edit' : '';
    const post = type.toLowerCase();
    return `customer.${pre}_${post}`;
  }, [mode, type]);

  return (
    <Page title={`${type}s | Storm`}>
      <Snackbar
        open={isSnackOpen}
        autoHideDuration={2000}
        onClose={handleSnackClose}
        message={snackMessage}
      />
      <FormDialog
        open={isCustomerDialogueOpen}
        handleClose={() => {
          setCustomerDialogueOpen(false);
        }}
        title={t(dialogueTitle)}
      >
        {getCustomerFormDialogue()}
      </FormDialog>
      <FormDialog
        open={isAddActionOpen}
        handleClose={() => {
          setAddActionOpen(false);
        }}
        title="New Action"
      >
        <AddActionForm onFormSubmit={onAddAction} actionList={actions} />
      </FormDialog>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {type}
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="#"
            onClick={() => {
              setMode(CustomerDialogMode.NEW);
              setCustomerDialogueOpen(true);
            }}
            startIcon={<Icon icon={plusFill} />}
          >
            {`New ${type}`}
          </Button>
        </Stack>

        <Card>
          <UserListToolbar
            customerType={type}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <DataTable
              minWidth={1200}
              tableHead={tableConfig}
              tableData={filteredCustomers}
              page={page}
              rowsPerPage={rowsPerPage}
              filterName={filterName}
              onOrderChange={onOrderChange}
              getMoreMenu={getMoreMenu}
            />
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={customers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
