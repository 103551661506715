import {Grid, Typography} from '@material-ui/core';


export default function CustomerDetails({type, customer}) {
  if(customer == null) {
    return null;
  }
  return <Grid container spacing={2} >
    <Grid item xs={12} sm={6}>
      <Typography>First Name</Typography>
      <Typography>{customer.name}</Typography>
    </Grid>
    <Grid item xs={12} sm={6}>
      <Typography>First Name</Typography>
    </Grid>
  </Grid>;
}

