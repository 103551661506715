import FormBuilder, { FIELD_TYPE } from '../../../FormBuilder';

const PROCEDURE_OPTIONS = [
  {title: 'No', value: 1},
  {title: 'Yes', value: 2},
];

const PROPERTY_USE_OPTIONS = [
  {title: 'RP', value: 1},
  {title: 'RS', value: 2},
  {title: 'RL', value: 3},
]

const FIELDS_CONFIG = [
{
  type: FIELD_TYPE.TEXT,
  break: 6,
  label: 'Joint property',
  key: 'joint_property',
},
{
  type: FIELD_TYPE.TEXT,
  break: 6,
  label: 'Contact details of the trustee',
  key: 'trustee_contact',
},
{
  type: FIELD_TYPE.NUMBER,
  break: 6,
  label: 'Number of the lot(s) sold',
  key: 'lots_sold',
},
{
  type: FIELD_TYPE.NUMBER,
  break: 6,
  label: 'Number of co-ownership lots',
  key: 'coownership_lots',
},
{
  type: FIELD_TYPE.TEXT,
  break: 6,
  label: 'Annual condominium fees',
  key: 'condominium_fees',
},
{
  type: FIELD_TYPE.SELECT,
  break: 6,
  label: 'Procedure in progress in condominium',
  key: 'procedure_progress',
  options: PROCEDURE_OPTIONS,
},
{
  type: FIELD_TYPE.SELECT,
  break: 12,
  label: 'Use of the property',
  key: 'property_use',
  options: PROPERTY_USE_OPTIONS,
}
];
export default function CustomerCoOwnershipFields({ getFieldProps, touched, errors }) {
  return <FormBuilder fieldsConfig={FIELDS_CONFIG} getFieldProps={getFieldProps} touched={touched} errors={errors} title="Co-ownership information" />;
}