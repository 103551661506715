import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import DashboardApp from './pages/DashboardApp';
import Customer from './pages/Customer';
import NotFound from './pages/Page404';
import Projects from './pages/Projects';
import { CustomerType } from './components/_dashboard/customers/CustomerUtils';
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'suspects', element: <Customer type={CustomerType.SUSPECT} /> },
        { path: 'prospects', element: <Customer type={CustomerType.PROSPECT} /> },
        { path: 'clients', element: <Customer type={CustomerType.CLIENT} /> },
        { path: 'projects', element: <Projects /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
